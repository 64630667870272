import moment from 'moment';
import Types from '../Types';
import { getCourseFromRoute } from './courseMixins';
import { getTeacherFromRoute } from './teacherMixins';

const reportCardMixins = {
    computed: {
        $_reportCardMixins_getMarkingPeriods() {
            return getMarkingPeriods(this.$store.state);
        },
    },
    methods: {
        $_reportCardMixins_getGrades(course) {
            const { database } = this.$store.state;
            return getReportCardGrades(database, course);
        },
    },
};

function getReportCardGrades(database, course) {
    const { courseSectionIds } = course;

    const studentMarkingPeriodGrades = database.studentMarkingPeriodGrades.filter((g) => courseSectionIds.includes(g.courseSectionId));

    return studentMarkingPeriodGrades;
}

function populateReportCardData(route, store, v) {
    const { schoolTermMarkingPeriodId } = route.params;
    if (!schoolTermMarkingPeriodId) return;

    const { showError } = v;
    const course = getCourseFromRoute(route, store);
    const teacher = getTeacherFromRoute(route, store);

    if (!course || !teacher) {
        window.console.log('Could not get course/teacher from route');
        return;
    }
    store.dispatch(Types.actions.POPULATE_REPORT_CARDS, {
        teacher,
        schoolTermMarkingPeriodId,
        callback(err) {
            if (err) return showError(err);
        },
    });
}

function getMarkingPeriods(state) {
    const shortDate = 'ddd, MMM Do';
    const markingPeriods = state.database.markingPeriods
        .map((x) => ({ ...x }))
        .filter((x) => x.deleted == false);

    const starsClassroom = Boolean(state.database.dataSystems.find((ds) => ds.dataSystemName == 'STARS Classroom'));
    const starsAdmin = Boolean(state.database.dataSystems.find((ds) => ds.dataSystemName == 'STARS Admin'));

    const output = markingPeriods.map((mp) => {
        const markingPeriod = { ...mp };
        // elem/stars classroom property
        markingPeriod.submission = null;
        // format the grade deadlines
        markingPeriod.submissionRange = moment(markingPeriod.submissionStart).format(shortDate);
        if (markingPeriod.submissionStartTime) {
            const d = `${markingPeriod.submissionStart} ${markingPeriod.submissionStartTime}`;
            markingPeriod.submissionRange = moment(d, 'YYYY-MM-DD HH:mm:ss').format('MMM Do, YYYY h:mm A');
        }
        markingPeriod.submissionRange += ' - ';
        if (markingPeriod.submissionEndTime) {
            const d = `${markingPeriod.submissionEnd} ${markingPeriod.submissionEndTime}`;
            markingPeriod.submissionRange += moment(d, 'YYYY-MM-DD HH:mm:ss').format('MMM Do, YYYY h:mm A');
        } else {
            markingPeriod.submissionRange += moment(markingPeriod.submissionEnd).format(shortDate);
        }
        // format start end dates for mp
        markingPeriod.markingPeriodRange = `${moment(markingPeriod.markingPeriodStart).format(shortDate)} - ${moment(markingPeriod.markingPeriodEnd).format(shortDate)}`;

        // determine submissionWindowVariables
        const now = moment().valueOf();
        const format = 'YYYY-MM-DD HH:mm:ss';
        const startDate = moment(`${mp.submissionStart} ${mp.submissionStartTime || '00:00:00'}`, format).valueOf();
        const endDate = moment(`${mp.submissionEnd} ${mp.submissionEndTime || '00:00:00'}`, format).valueOf();
        markingPeriod.isBeforeSubmissionWindow = Boolean(now < startDate);
        markingPeriod.isAfterSubmissionWindow = Boolean(now > endDate);
        markingPeriod.isWithinSubmissionWindow = Boolean(!markingPeriod.isBeforeSubmissionWindow && !markingPeriod.isAfterSubmissionWindow);

        // are files configured for both data systems
        const canStarsClassroom = starsClassroom && mp.submission;
        const canStarsAdmin = starsAdmin && mp.starsGradingEnabled;
        markingPeriod.isConfigured = Boolean(canStarsClassroom || canStarsAdmin);
        markingPeriod.enabled = Boolean(markingPeriod.isWithinSubmissionWindow && markingPeriod.isConfigured);

        return markingPeriod;
    }).sort((a, b) => ((a.markingPeriod > b.markingPeriod) ? 1 : ((b.markingPeriod > a.markingPeriod) ? -1 : 0)));
    return output;
}
export {
    reportCardMixins as default,
    populateReportCardData,
    getMarkingPeriods,
    getReportCardGrades,
};
