<template>
<b-modal
    ref="pdf-view-modal"
    class="pdf-view-modal"
    size="xl"
    variant="primary"
    hide-footer
    centered
>
    <template #modal-header>
        <h5 class="modal-title">
            <a
                :href="pdfPath"
                target="_blank"
            >
                Download
            </a>
        </h5>

        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hideModal()"
        />
    </template>
    <template #default>
        <div class="modal-body">
            <object
                :data="pdfPath"
                type="application/pdf"
            >
                <a
                    class="btn btn-primary pull-right"
                    :href="pdfPath"
                    target="_blank"
                >
                    <i class="la la-file-pdf-o" />
                    Download PDF
                </a>
            </object>
        </div>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'PDFViewModal',
    data() {
        return {
            pdfPath: null,
        };
    },
    computed: mapState({
        user: (state) => state.user,
    }),
    methods: {
        open(pdfPath) {
            const v = this;
            v.pdfPath = null;
            v.pdfPath = pdfPath;
            v.$refs['pdf-view-modal'].show();
        },
        hideModal() {
            const v = this;
            v.$refs['pdf-view-modal'].hide();
        },
    },
};

</script>

<style scoped>
object {
    width: 100%;
    height: 85vh;
}

.pdf-view-modal .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
}
</style>
