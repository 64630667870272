var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "pdf-view-modal",
    staticClass: "pdf-view-modal",
    attrs: { size: "xl", variant: "primary", "hide-footer": "", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _c("a", { attrs: { href: _vm.pdfPath, target: "_blank" } }, [
                _vm._v(" Download "),
              ]),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return _vm.hideModal()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "object",
                { attrs: { data: _vm.pdfPath, type: "application/pdf" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary pull-right",
                      attrs: { href: _vm.pdfPath, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "la la-file-pdf-o" }),
                      _vm._v(" Download PDF "),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }